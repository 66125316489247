<template>
  <div class="line-item flex items-center gap-xs">
    <LcLotteryNumbers
      v-bind="{ size, spacing }"
      :fields="preparedFields"
      :style="[...addOnThemes, ...additionalThemes, extraTheme]"
    >
      <template
        v-for="addOn in data?.lowlightAddOns"
        :key="addOn"
        #[`number-${addOn}`]="{ number }"
      >
        {{ getLowlightText(addOn, number) }}
      </template>

      <template
        v-for="{ name, src } of data?.highlightAddOns"
        :key="name"
        #[`highlight-${name}`]
      >
        <img v-bind="{ src }" :alt="name" class="highlight" />
      </template>

      <template v-if="$slots['field-divider']" #field-divider>
        <slot name="field-divider" />
      </template>
    </LcLotteryNumbers>

    <strong v-if="perPlayAddOn" class="per-play-add-on font-regular">
      {{ perPlayAddOnTranslation }}
    </strong>
  </div>
</template>

<script lang="ts" setup>
import {
  type LcLotteryNumber,
  LcLotteryNumbers,
  type LcLotteryNumbersField,
  type LcLotteryNumbersProps,
} from '@lottocom/frontend-components'

import type {
  AddOn,
  AddOnOutput,
  LotteryId,
} from '~/@types/generated/backend/graphql-schema-types'

export type LineItemAddOn = Pick<AddOn, 'name' | 'perPlay'>
export type LineItemField = LcLotteryNumbersField & {
  modifies?: Maybe<string>
}
export type LineItemPerPlayAddOn = Omit<AddOnOutput, '__typename'>

export type LineItemProps = Omit<LcLotteryNumbersProps, 'fields'> & {
  addOns?: LineItemAddOn[]
  fields: LineItemField[]
  highlightAddOn?: boolean
  licensedTerritory: string
  lotteryId: LotteryId
  perPlayAddOn?: Maybe<LineItemPerPlayAddOn>
}

const toName = ({ name }: LineItemAddOn) => name.toLowerCase()
const isNotPerPlay = ({ perPlay }: LineItemAddOn) => !perPlay

/*********************************************************************************
 * Attention!
 * The component is incomplete and must be extended for further (later) use cases
 * *******************************************************************************/

const props = withDefaults(defineProps<LineItemProps>(), {
  addOns: () => [],
  highlightAddOn: false,
  perPlayAddOn: null,
})

const { getTranslation } = useI18nUtils()
const { getPerPlayTranslation } = useTranslations()

const { highlightAddOn, licensedTerritory, lotteryId } = toRefs(props)
const addOns = computed(() => props.addOns.filter(isNotPerPlay).map(toName))

const { data } = await useLineItem(
  licensedTerritory,
  lotteryId,
  highlightAddOn,
  addOns,
)

const getLowlightText = (addOnName: string, number?: number | string) => {
  if (typeof number === 'undefined') return '-'
  return getTranslation({
    fallback: String(number),
    key: `lineitem.number.addon.${addOnName}`,
    values: { number },
  })
}

const addOnThemes = computed(() => {
  const { createCssVar } = lotteryCssVarConstructor.value

  return addOns.value.map((name) => ({
    [`--lottery-numbers-${name}-bg`]: createCssVar('add-on-bg', 'transparent'),
    [`--lottery-numbers-${name}-border`]: createCssVar(
      'add-on-border',
      '0.125rem solid var(--add-on-border-fallback-color)',
    ),
    [`--lottery-numbers-${name}-color`]: createCssVar('add-on-color'),
  }))
})
const additionalThemes = computed(() => {
  const { createCssVar } = lotteryCssVarConstructor.value

  return preparedFields.value
    .filter(
      ({ name }) =>
        !name.match(/^([A-Z]{1}|BASE)$/) &&
        !addOns.value.find((addOn) => name.toLowerCase() === addOn),
    )
    .map(({ name }) => [
      {
        [`--lottery-numbers-${name.toLowerCase()}-bg`]: createCssVar(
          `${name.toLowerCase()}-bg`,
        ),
        [`--lottery-numbers-${name.toLowerCase()}-border`]: createCssVar(
          `${name.toLowerCase()}-border`,
        ),
        [`--lottery-numbers-${name.toLowerCase()}-color`]: createCssVar(
          `${name.toLowerCase()}-color`,
        ),
      },
    ])
})
const extraTheme = computed(() => {
  const { createCssVar } = lotteryCssVarConstructor.value

  return {
    '--lottery-numbers-extra-bg': createCssVar('extra-ball-bg'),
    '--lottery-numbers-extra-color': createCssVar('extra-ball-color'),
  }
})
const preparedFields = computed(() =>
  props.fields.reduce<LineItemField[]>((fields, field) => {
    if (!field.modifies) return [...fields, field]

    const sourceFieldIndex = fields.findIndex(
      ({ name }) => name === field.modifies,
    )
    if (sourceFieldIndex === -1) return fields

    field.numbers
      .map((number) =>
        getModifiedNumberIndexes(fields[sourceFieldIndex], number),
      )
      .filter((indexToBeReplaced) => indexToBeReplaced >= 0)
      .forEach((indexToBeReplaced) => {
        fields = [
          ...fields.slice(0, sourceFieldIndex),
          ...spliceInModifiedField(
            fields[sourceFieldIndex],
            field,
            indexToBeReplaced,
          ),
        ]
      })

    return fields
  }, []),
)

const lotteryCssVarConstructor = computed(() =>
  getLotteryCssVarConstructor(licensedTerritory.value, lotteryId.value),
)
const perPlayAddOnTranslation = computed(
  () =>
    props.perPlayAddOn &&
    getPerPlayTranslation(
      lotteryId.value,
      props.perPlayAddOn.name,
      props.perPlayAddOn.value,
    ),
)

const getModifiedNumberIndexes = (
  sourceField: LineItemField,
  number: LcLotteryNumber,
) =>
  sourceField.numbers.findIndex(
    (sourceNumber) => sourceNumber.value === number.value,
  )

const spliceInModifiedField = (
  sourceField: LineItemField,
  modifiedField: LineItemField,
  indexToBeReplaced: number,
) => [
  {
    ...sourceField,
    numbers: sourceField.numbers.slice(0, indexToBeReplaced),
  },
  {
    ...modifiedField,
    numbers: [sourceField.numbers[indexToBeReplaced]],
  },
  {
    ...sourceField,
    numbers: sourceField.numbers.slice(indexToBeReplaced + 1),
  },
]
</script>

<style lang="scss" scoped>
.line-item {
  .lc-lottery-numbers {
    --add-on-border-fallback-color: #{color('secondary600')};
  }

  .highlight {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    max-width: unset;
    max-height: 100%;
  }

  .per-play-add-on {
    @include media-mobile-small {
      font-size: $font-size-sm;
    }
  }
}
</style>
