import type { LotteryId } from '~/@types/generated/backend/graphql-schema-types'

type HighlightAddOn = {
  name: string
  src: string
}

const isNotIn = (highlights: HighlightAddOn[]) => (addOnName: string) =>
  !highlights.some(({ name: highlightName }) => highlightName === addOnName)

const removeNulls = (possibleHighlights: Maybe<HighlightAddOn>[]) =>
  possibleHighlights.filter(isTruthy)

export const useLineItem = (
  licensedTerritory: Ref<string>,
  lotteryId: Ref<LotteryId>,
  highlightAddOn: Ref<boolean>,
  addOns: Ref<string[]>,
) => {
  const { getLotteryHighlight } = useAsset()

  const getHighlights = async () => {
    if (!highlightAddOn.value) return Promise.resolve([])
    const possibleHighlights = addOns.value.map(toHighlightAddOn)
    return await Promise.all(possibleHighlights).then(removeNulls)
  }

  const getLowlights = (highlights: HighlightAddOn[]) =>
    addOns.value.filter(isNotIn(highlights))

  const splitAddOns = async () => {
    const highlightAddOns = await getHighlights()
    const lowlightAddOns = getLowlights(highlightAddOns)

    return Promise.resolve({ highlightAddOns, lowlightAddOns })
  }

  const toHighlightAddOn = async (
    name: string,
  ): Promise<Maybe<HighlightAddOn>> => {
    const src = await getLotteryHighlight(name)
    return src ? { name, src } : null
  }

  return useAsyncData(
    `${licensedTerritory.value}_${lotteryId.value}_addons`,
    splitAddOns,
    { watch: [licensedTerritory, lotteryId, highlightAddOn] },
  )
}
